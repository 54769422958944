import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button, Typography, Link } from '@mui/material';
import Container from '@mui/material/Container';
import { useSelector ,
   useDispatch
   } from 'react-redux';
import axios from 'axios';
import * as constants from '../../helpers/constants'
import Loader from '../../components/Loader';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

const GenerationTransactions = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const alert = useAlert();
  const navigate = useNavigate()
  const [transactions, setTransactions] = useState([])

  const getTransactions = () => {
    dispatch(addLoadingFlag('get-transactions'));
    axios
    .get(
      constants.BASE_URL + 'api/ai/get-transactions',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      dispatch(removeLoadingFlag('get-transactions'));
      alert.show(
        `Got transactions`,
        { type: 'success' }
      );
      setTransactions(response.data)
    }).catch(function (error) {
      console.log(error);
      if (error.response.data === 'Permission denied') {
        navigate('/#');
      }
      dispatch(removeLoadingFlag('get-prompts'));
    });
  }
    useEffect(()=> {
      getTransactions()
    },[alert,dispatch,token])

    const convertTimestamp = (timestamp) => {
      // Ensure the timestamp is parsed correctly into a number
      const timestampNumber = parseInt(timestamp, 10);
    
      // Check if the parsed timestamp is a valid number
      if (isNaN(timestampNumber)) {
        return "Invalid timestamp";
      }
    
      const date = new Date(timestampNumber); // No need to multiply by 1000 for milliseconds
    
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
    
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    if (loading) {
      return <Loader />
    }
    return (
        <Container component="main" maxWidth='lg'>
          <CssBaseline />
          {!loading && <>
            <Box
                 sx={{
                  position: 'fixed',
                  top: 60, 
                  left: 0, 
                  width: '100%', 
                  display: 'flex',
                  flexDirection: 'row', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  backgroundColor: constants.DARK_GREY, 
                  zIndex: 10000, 
                  gap: {
                    sm: 'none',
                    md: '30px',
                  }
                }}
              >
                <Button
          onClick={() => navigate('/generation-transactions')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Transactions
        </Button>
        <Button
          onClick={() => navigate('/fees')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Fees
        </Button>
        <Button
          onClick={() => navigate('/prompts')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Prompts
        </Button>
        <Button
          onClick={() => navigate('/users')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Users
        </Button>
          </Box>
              <Box
                sx={{
                  marginTop: {
                    xs: 15,
                    md: 15,
                  },
                  marginBottom: 4,
                  display: {
                    xs: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  flexDirection: 'column',
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gridTemplateColumns: '1fr 1fr',
                  gap: '30px 0',
                  position: 'relative',
                  paddingBottom: '100px',
                }}
              >
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: '-1px',
                    padding: '10px',
                    left: '0',
                    zIndex: '10',
                    display: 'flex',
                    justifyContent: 'center',
                    right: '0',
                    width: '100%',
                    background: constants.DARK_GREY
                  }}
                >
              </Box>
              
             {transactions.reverse().map(transaction => 
               <Box
               key={transaction._id}
                  sx={{
                    padding: '15px',
                    border: '1px solid white',
                    borderRadius: '10px',
                    width: '70vw'
                  }}
                  fullWidth
               >
                <Typography>User email: {transaction?.email}</Typography>
                <Typography>Generated field: {transaction?.generatedField}</Typography>
                <Typography>Generated tab: {transaction?.generatedTab}</Typography>
                <Typography>Tokens used: {transaction?.totalTokens}</Typography>
                <Typography>Time: {convertTimestamp(transaction?.timestamp)}</Typography>   
              </Box>)
              }
          
            </Box>
          </>}
      </Container>
  );
};

export default GenerationTransactions;

