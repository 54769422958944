import React, { useState } from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/slices/authSlice';
import { addLoadingFlag,removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useNavigate } from 'react-router-dom';
import * as constants from '../../helpers/constants'
import axios from 'axios';
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import logo from '../../assets/images/Logo.png'
import CardMedia from '@mui/material/CardMedia';
import backgroundImage from '../../assets/images/login-background.png';
import { setTokens } from '../../store/slices/authSlice';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        Storybuilt.AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [checked, setChecked] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const loading = useSelector((state) => state.loading.loading);

  const handleSubmit = async (e) => {
    if (loading) {
      return
    }
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (!constants.EMAIL_REGEXP.test(data.get('email'))) {
      setIsEmailValid(false);
      alert.show('Email is not valid!',{type: 'error'})
      return
    } else if (data.get('password').length < 6) {
      setIsPasswordValid(false);
      alert.show('Password should be longer!',{type: 'error'})
      return
    } else {
      dispatch(addLoadingFlag('login'));
      await axios.post(constants.BASE_URL + 'api/auth/login', {
        email: data.get('email'),
        password: data.get('password')
      })
      .then(function (response) {
        const payload = {
          user: data.get('email'),
          token: response.data.token,
          userId: response.data.userId,
          generationTokens: 0,
          hideTokensFillModal: !!response.data?.hideTokensFillModal,
        };
        if (checked) {
          localStorage.setItem('email', data.get('email'))
          localStorage.setItem('access_token', response.data.token)
          localStorage.setItem('userId', response.data.userId)
          localStorage.setItem('tutorialFlag', response.data.tutorialFlag)
          localStorage.setItem('role', response.data.role)
          localStorage.setItem('hideTokensFillModal', !!response.data?.hideTokensFillModal)
        }
        dispatch(setUser(payload));
        navigate('/products')
        setIsEmailValid(true);
        setIsPasswordValid(true);
        dispatch(removeLoadingFlag('login'));
        getUser(response.data.token)
      })
      .catch(function (error) {
        setIsEmailValid(false);
        setIsPasswordValid(false);
        dispatch(removeLoadingFlag('login'));
        alert.show('Wrong credentials, or user not found',{type: 'error'})
      });
    }
  };

  const getUser = (token) => {
    axios
    .get(
      constants.BASE_URL + 'api/auth/get-user-info',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      console.log(response.data)
      setUser(response.data)
      dispatch(setTokens(response.data?.tokens))
    }).catch(function (error) {
      console.log(error);
    });
  }
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  if (loading) {
    return <Loader />
  }

    return (
      <Box
      sx={{
        backgroundImage: {
          md: `url(${backgroundImage})`,
          xs: 'none'
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vw',
        height: '100vh',
        marginTop: '0px',
        paddingTop: '86px',
      }}>
        <Container component="main" maxWidth="xs" 
          sx={{
            background: `${constants.DARK_GREY}`,
            padding: '24px',
            borderRadius: '15px',
            marginTop:  {
              md: `0px`,
              xs: '0'
            },
            marginLeft:  {
              md: `100px`,
              xs: 'auto'
            },
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              // marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: constants.ORANGE }}> */}
              {/* <LockOutlinedIcon /> */}
              <CardMedia
                  component="img"
                  sx={{
                      height: 160,
                      width: 'auto',
                      cursor: 'pointer'
                    }}
                  image={logo}
                  alt="#"
              />
            {/* </Avatar> 
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>*/}
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={!isEmailValid}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!isPasswordValid}
              />
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: '#fff' }}
              >
                Sign In
              </Button>
              <Grid container>
                {/* <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid> */}
                <Grid item>
                  {/* <Link href="register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link> */}
                  <Link href="contact" variant="body2">
                    {"Don't have an account? Contact us!"}
                  </Link> 
                  {/* or  <Link href="register" variant="body2">
                    {"Sign up"}
                  </Link> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 4 }} />
        </Container>
        </Box>
  );
};

export default Login;
