import React from 'react'
import Header from './components/Header';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import Login from './pages/Login';
import ProtectedRoute from './utils/auth-routes/ProtectedRoute';
import Projects from './pages/Projects'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import Project from './pages/Project';
import Register from './pages/Register';
import ErrorBoundary from './utils/ErrorBoundary';
import Prompts from './pages/Prompts'
import Users from './pages/Users'
import UsersManagement from './pages/UsersManagement'
import './style.css'
import User from './pages/User';
import Contact from './pages/Contact';
import ProjectInfo from './pages/ProjectInfo';
import Products from './pages/Products';
import GenerationTransactions from './pages/GenerationTransactions';
import Fees from './pages/Fees';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zIndex: '999999999'
  }
}

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <AlertProvider template={AlertTemplate} {...options}>
          <BrowserRouter>
          <Header />
            <Routes>
              <Route path="/login" element={<Login />} />
              {/* <Route path="/register" element={<Register />} /> */}
              <Route path="/contact" element={<Contact />} />
              <Route path="/projects" element={<ProtectedRoute><Projects /></ProtectedRoute>} />
              <Route path="/generation-transactions" element={<ProtectedRoute><GenerationTransactions /></ProtectedRoute>} />
              <Route path="/products" element={<ProtectedRoute><Products /></ProtectedRoute>} />
              <Route path="/prompts" element={<ProtectedRoute><Prompts /></ProtectedRoute>} />
              <Route path="/fees" element={<ProtectedRoute><Fees /></ProtectedRoute>} />
              <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
              <Route path="/users-management" element={<ProtectedRoute><UsersManagement /></ProtectedRoute>} />
              <Route path="/user-info" element={<ProtectedRoute><User /></ProtectedRoute>} />
              <Route path="/project-info" >
              <Route index element={<ProtectedRoute><ProjectInfo /></ProtectedRoute>} />
                <Route path="/project-info/:id" element={<ProtectedRoute><ProjectInfo /></ProtectedRoute>} />
              </Route>
              <Route path="/project" >
                <Route index element={<ProtectedRoute><Projects /></ProtectedRoute>} />
                <Route path='/project/:id' element={<ProtectedRoute><Project /></ProtectedRoute>}  />
              </Route>
              <Route path="*" exact element={<ProtectedRoute><Projects /></ProtectedRoute>} />
            </Routes>
          </BrowserRouter>
        </AlertProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
