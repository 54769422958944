import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, TextField, Typography, MenuItem,	FormControl, InputLabel, Select, Link } from '@mui/material';
import Container from '@mui/material/Container';
import { useSelector ,
   useDispatch
   } from 'react-redux';
import axios from 'axios';
import * as constants from '../../helpers/constants'
import Loader from '../../components/Loader';
import Button from '@mui/material/Button';
import { addLoadingFlag, removeLoadingFlag } from '../../store/slices/loadingSlice';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

const Prompts = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const loading = useSelector((state) => state.loading.loading);
  const alert = useAlert();
  const typesArray = constants.PROMPTLIST;
  const [type_of_story, setType_of_story] = useState(typesArray[0]);

  
  const [projectState, setProjectState] = useState({
    title: '',
    tagline: '',
    //rules: '',
    'description/treatment': '',
  });
  

  const [characterState, setCharacterState] = useState({
    name: '',
    age: '',
    origin: '',
    skills: '',
    look: '',
    motivation: '',
    gender: '',
    emotions: '',
    backstory: '',
  });
  const [settingState, setSettingState] = useState({
    title: '',
    environment: '',
    area: '',
    time_of_year: '',
    unique_characteristics: '',
    atmosphere: '',
    climate: '',
  });

 const initialStoryState = typesArray.reduce((acc, type) => {
    acc[type] = {
      summary: '',
      list_of_acts: '',
      list_of_scenes: '',
      list_of_beats_for_the_scene: '',
      scene_full_script: '',
    };
    return acc;
  }, {});

  // Include items not belonging to a specific type
  initialStoryState.list_of_beats_for_the_scene = '';
  initialStoryState.list_of_scenes = '';
  initialStoryState.list_of_acts = '';
  initialStoryState.scene_full_script = '';
  initialStoryState.summary = '';

  const [storyState, setStoryState] = useState(initialStoryState);

  const [imageState, setImageState] = useState({
    negativeStyles: [],
    oneImageGenerationCost: '0'
  });

  const handleProjectChange = (field, value) => {
    setProjectState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
 
  const handleCharacterChange = (field, value) => {
    setCharacterState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleImageChange = (field, value) => {
    setImageState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSettingChange = (field, value) => {
    setSettingState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleStoryChange = (field, value, commonFlag = false) => {
    if (!commonFlag) {
      setStoryState((prevState) => ({
        ...prevState,
        [type_of_story]: {
          ...prevState[type_of_story],
          [field]: value},
      }));
    } else {
      setStoryState((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  useEffect(()=> {
    dispatch(addLoadingFlag('get-prompts'));
    axios
    .get(
      constants.BASE_URL + 'api/ai/get-prompts',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      dispatch(removeLoadingFlag('get-prompts'));
      alert.show(
        `Prompts were successfully changed`,
        { type: 'success' }
      );
      if(response.data.hasOwnProperty('project')){
        setProjectState(response.data.project)
      }
      if(response.data.hasOwnProperty('characters')){
        setCharacterState(response.data.characters)
      }
      if(response.data.hasOwnProperty('setting')){
        setSettingState(response.data.setting)
      }
      if(response.data.hasOwnProperty('story')){
        setStoryState(response.data.story)
      }
      if(response.data.hasOwnProperty('image')){
        setImageState(response.data.image)
      }
    }).catch(function (error) {
      if (error.response.data === 'Permission denied') {
        navigate('/#');
      }
      console.log(error);
      dispatch(removeLoadingFlag('get-prompts'));
    });
  },[alert,dispatch,token])
  const generatePrompts = () => {
    dispatch(addLoadingFlag('prompts'));
    axios
    .post(
      constants.BASE_URL + 'api/ai/generate-prompts',{
        project: projectState,
        characters: characterState,
        setting: settingState,
        story: storyState,
        image: imageState,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(function (response) {
      dispatch(removeLoadingFlag('prompts'));
      alert.show(
        `Prompts were successfully changed`,
        { type: 'success' }
      );
      console.log(response);  
    }).catch(function (error) {
      if (error.response.data === 'Permission denied') {
        navigate('/#');
      }
      console.log(error);
      dispatch(removeLoadingFlag('prompts'));
    });
  }

    if (loading) {
      return <Loader />
    }
    return (
        <Container component="main" maxWidth='lg'>
          <CssBaseline />
          {!loading && <>
            <Box
                 sx={{
                  position: 'fixed',
                  top: 60, 
                  left: 0, 
                  width: '100%', 
                  display: 'flex',
                  flexDirection: 'row', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  backgroundColor: constants.DARK_GREY, 
                  zIndex: 10000, 
                  gap: {
                    sm: 'none',
                    md: '30px',
                  }
                }}
              >
                <Button
          onClick={() => navigate('/generation-transactions')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Transactions
        </Button>
        <Button
          onClick={() => navigate('/fees')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Fees
        </Button>
        <Button
          onClick={() => navigate('/prompts')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Prompts
        </Button>
        <Button
          onClick={() => navigate('/users')}
          variant='contained'
          component='label'
          sx={{
            height: '30px',
            color: 'white',
            marginTop: '8px',
            marginBottom: '8px',
            fontSize: 
            {
              sm: '14px',
              md: '20px',
            },
          }}
        >
          Users
        </Button>
          </Box>
              <Box
                sx={{
                  marginTop: {
                    xs: 15,
                    md: 15,
                  },
                  marginBottom: 4,
                  display: {
                    xs: 'flex',
                  },
                  alignItems: 'center',
                  justifyContent: {
                    md: 'space-between',
                    xs: 'space-around',
                  },
                  flexDirection: 'column',
                  flexWrap: {
                    xs: 'wrap',
                  },
                  gridTemplateColumns: '1fr 1fr',
                  gap: '30px 0',
                  position: 'relative',
                  paddingBottom: '100px',
                }}
              >
                <Box
                  sx={{
                    position: 'fixed',
                    bottom: '-1px',
                    padding: '10px',
                    left: '0',
                    zIndex: '10',
                    display: 'flex',
                    justifyContent: 'center',
                    right: '0',
                    width: '100%',
                    background: constants.DARK_GREY
                  }}
                >
                  <Button
                  onClick={() => generatePrompts()}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '56px',
                    color: 'white',
                    marginTop: '16px',
                    marginBottom: '8px',
                    fontSize: '36px',
                  }}
                >
                  SAVE
                </Button>
              </Box>
               
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
                <Typography>Project AI Prompts</Typography>
                <TextField
                  margin='normal'
                  fullWidth
                  name='title'
                  label='Project Title'
                  id='title'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={projectState.title}
                  onChange={(e) => handleProjectChange('title', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='tagline'
                  label='Tagline'
                  id='tagline'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={projectState.tagline}
                  onChange={(e) => handleProjectChange('tagline', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='description/treatment'
                  label='Treatment / Description'
                  id='description/treatment'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={projectState['description/treatment']}
                  onChange={(e) => handleProjectChange('description/treatment', e.target.value)}
                />
                {/* <TextField
                  margin='normal'
                  fullWidth
                  name='rules'
                  label='Rules'
                  id='rules'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={projectState.rules}
                  onChange={(e) => handleProjectChange('rules', e.target.value)}
              />   */}
              </Box>
              
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
                <Typography>Character AI Prompts</Typography>
                <TextField
                  margin='normal'
                  fullWidth
                  name='name'
                  label='Name'
                  id='name'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={characterState.name}
                  onChange={(e) => handleCharacterChange('name', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='age'
                  label='Age'
                  id='age'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={characterState.age}
                  onChange={(e) => handleCharacterChange('age', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='gender'
                  label='Gender'
                  id='gender'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={characterState.gender}
                  onChange={(e) => handleCharacterChange('gender', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='origin'
                  label='Where are they from?'
                  id='origin'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={characterState.origin}
                  onChange={(e) => handleCharacterChange('origin', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='look'
                  label='What is their physical description?'
                  id='look'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={characterState.look}
                  onChange={(e) => handleCharacterChange('look', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='skills'
                  label='Is there something unique about them? Unique skills?'
                  id='skills'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={characterState.skills}
                  onChange={(e) => handleCharacterChange('skills', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='motivation'
                  label='What motivates this character to action?'
                  id='motivation'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={characterState.motivation}
                  onChange={(e) => handleCharacterChange('motivation', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='backstory'
                  label="What is this character's backstory?"
                  id='backstory'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={characterState.backstory}
                  onChange={(e) => handleCharacterChange('backstory', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='emotions'
                  label='What emotional traits does this character display?'
                  id='emotions'
                  multiline
                  InputLabelProps={{ shrink: true }}
                  minRows={3}
                  maxRows={6}
                  value={characterState.emotions}
                  onChange={(e) => handleCharacterChange('emotions', e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
                <Typography>Setting AI Prompts</Typography>
                <TextField
                  margin='normal'
                  fullWidth
                  name='title'
                  label='Title'
                  id='title'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={settingState.title}
                  onChange={(e) => handleSettingChange('title', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='environment'
                  label='What is it?'
                  id='environment'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={settingState.environment}
                  onChange={(e) => handleSettingChange('environment', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='area'
                  label='Where is it?'
                  id='area'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={settingState.area}
                  onChange={(e) => handleSettingChange('area', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='unique_characteristics'
                  label='What is unique about it?'
                  id='unique_characteristics'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={settingState.unique_characteristics}
                  onChange={(e) => handleSettingChange('unique_characteristics', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='time_of_year'
                  label='What time, year, or season is it?'
                  id='time_of_year'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={settingState.time_of_year}
                  onChange={(e) => handleSettingChange('time_of_year', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='atmosphere'
                  label='Is there a mood or atmosphere about this particular setting?'
                  id='atmosphere'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={settingState.atmosphere}
                  onChange={(e) => handleSettingChange('atmosphere', e.target.value)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='climate'
                  label='Does the climate play a role in the story?'
                  id='climate'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={settingState.climate}
                  onChange={(e) => handleSettingChange('climate', e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
                <Typography sx={{ marginBottom: '8px'}}>Story Generator AI Prompts</Typography>
                <TextField
                  margin='normal'
                  fullWidth
                  name='summary'
                  label='Summary'
                  id='summary'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={storyState.summary}
                  onChange={(e) => handleStoryChange('summary', e.target.value,true)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='list_of_acts'
                  label='Create a List of Acts'
                  id='list_of_acts'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={storyState.list_of_acts}
                  onChange={(e) => handleStoryChange('list_of_acts', e.target.value, true)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='list_of_scenes'
                  label='Create a List of Scenes'
                  id='list_of_scenes'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={storyState.list_of_scenes}
                  onChange={(e) => handleStoryChange('list_of_scenes', e.target.value, true)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='list_of_beats_for_the_scene'
                  label='Create Story Beats for this Scene'
                  id='list_of_beats_for_the_scene'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={storyState.list_of_beats_for_the_scene}
                  onChange={(e) => handleStoryChange('list_of_beats_for_the_scene', e.target.value, true)}
                />
                <TextField
                  margin='normal'
                  fullWidth
                  name='scene_full_script'
                  label={`Now Let's Write the Scene`}
                  id='scene_full_script'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={storyState.scene_full_script}
                  onChange={(e) => handleStoryChange('scene_full_script', e.target.value, true)}
                />
                <Box
                  sx={{
                    padding: '15px',
                    border: '1px solid white',
                    borderRadius: '10px',
                    width: '100%',
                    marginTop: '8px'
                  }}
                  fullWidth
                >
                <FormControl
                  fullWidth
                  variant='outlined'
                  style={{ marginTop: "4px" }}
                >
                  <InputLabel
                    variant='outlined'
                    id='test-select-label'
                    shrink={true}
                  >
                    Type of Story
                  </InputLabel>
                  {Date.now() &&
                    <Select
                      value={type_of_story}
                      onChange={(e) => setType_of_story(e.target.value)}
                      labelId='test-select-label'
                      variant='outlined'
                      label='Type of Story'
                      fullWidth
                      notched={true}
                      InputLabelProps={{ shrink: true }}
                    >
                      {typesArray?.map(item => <MenuItem value={item}>{item}</MenuItem>)}
                    </Select>
                  }
                </FormControl>
                  <TextField
                    margin='normal'
                    fullWidth
                    name='summary'
                    label='Summary'
                    id='summary'
                    InputLabelProps={{ shrink: true }}
                    multiline
                    minRows={3}
                    maxRows={6}
                    value={storyState[type_of_story]?.summary}
                    onChange={(e) => handleStoryChange('summary', e.target.value)}
                  />
                  <TextField
                    margin='normal'
                    fullWidth
                    name='list_of_acts'
                    label='List of Acts'
                    id='list_of_acts'
                    InputLabelProps={{ shrink: true }}
                    multiline
                    minRows={3}
                    maxRows={6}
                    value={storyState[type_of_story]?.list_of_acts}
                    onChange={(e) => handleStoryChange('list_of_acts', e.target.value)}
                  />
                  <TextField
                    margin='normal'
                    fullWidth
                    name='list_of_scenes'
                    label='List of Scenes'
                    id='list_of_scenes'
                    InputLabelProps={{ shrink: true }}
                    multiline
                    minRows={3}
                    maxRows={6}
                    value={storyState[type_of_story]?.list_of_scenes}
                    onChange={(e) => handleStoryChange('list_of_scenes', e.target.value)}
                  />
                  <TextField
                    margin='normal'
                    fullWidth
                    name='list_of_beats_for_the_scene'
                    label='Create Story Beats for this Scene'
                    id='list_of_beats_for_the_scene'
                    InputLabelProps={{ shrink: true }}
                    multiline
                    minRows={3}
                    maxRows={6}
                    value={storyState[type_of_story]?.list_of_beats_for_the_scene}
                    onChange={(e) => handleStoryChange('list_of_beats_for_the_scene', e.target.value)}
                  />
                  <TextField
                    margin='normal'
                    fullWidth
                    name='scene_full_script'
                    label={`Now Let's Write the Scene`}
                    id='scene_full_script'
                    InputLabelProps={{ shrink: true }}
                    multiline
                    minRows={3}
                    maxRows={6}
                    value={storyState[type_of_story]?.scene_full_script}
                    onChange={(e) => handleStoryChange('scene_full_script', e.target.value)}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  padding: '15px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  width: '70vw'
                }}
                fullWidth
              >
              <Typography>Image Generator AI Prompts</Typography>
             
              <Box sx={{
                display: 'flex',
                gap: '10px'
              }}>
                <TextField
                  margin='normal'
                  fullWidth
                  name='testImage'
                  label={`Image prompts`}
                  id='testImage'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={imageState.negativeStyles}
                  onChange={(e)=> handleImageChange('negativeStyles',e.target.value)}
                />
               
                <Button
                  onClick={() => handleImageChange('negativeStyles','no letters, no text, no typographic, no pictures, no paper, no legend')}
                  variant='contained'
                  component='label'
                  sx={{
                    height: '56px',
                    color: 'white',
                    marginTop: '16px',
                    marginBottom: '8px',
                    fontSize: '16px',
                    textAlign: 'center'
                  }}
                >
                  Set to default
                </Button> 
              </Box>
             <TextField
                  margin='normal'
                  fullWidth
                  name='imageCost'
                  label={`One image generation tokens cost`}
                  id='imageCost'
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={3}
                  maxRows={6}
                  value={imageState.oneImageGenerationCost}
                  onChange={(e)=> handleImageChange('oneImageGenerationCost',e.target.value)}
                />
              </Box>
            </Box>
          </>}
      </Container>
  );
};

export default Prompts;

