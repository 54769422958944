import React, { useState } from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import * as constants from '../../helpers/constants'
import axios from 'axios';
import { useAlert } from 'react-alert'
import logo from '../../assets/images/Logo.png'
import CardMedia from '@mui/material/CardMedia';
import { useSelector } from 'react-redux';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        Storybuilt.AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Contact = () => {
  const alert = useAlert();
  const [isEmailValid, setIsEmailValid] = useState(true)
  const stateLoading = useSelector((state) => state.loading.loading);

  const handleSubmit = async (e) => {
    if (stateLoading) {
      return
    }
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (!constants.EMAIL_REGEXP.test(data.get('email'))) {
      setIsEmailValid(false);
      alert.show('Email is not valid.',{type: 'error'})
      return
    } else {
      await axios.post(constants.BASE_URL + 'api/auth/contact', {
        email: data.get('email'),
      })
      .then(function (response) {
        setIsEmailValid(true);
        alert.show('Form data successfully sent',{type: 'success'})
      })
      .catch(function (error) {
        setIsEmailValid(false);
        alert.show('Wrong credentials or user may already exist',{type: 'error'})
      });
    }
  };

    return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: constants.ORANGE }}> */}
              {/* <LockOutlinedIcon /> */}
              <CardMedia
                  component="img"
                  sx={{
                      height: 60,
                      width: 'auto',
                      cursor: 'pointer'
                    }}
                  image={logo}
                  alt="#"
              />
            {/* </Avatar> */}
            <Typography component="h2" variant="h5" sx={{
              textAlign: 'center',
              margin: '10px'
            }}>
              Join our Beta Launch!
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={!isEmailValid}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, color: '#fff' }}
              >
                Send
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
  );
};

export default Contact;
